@import "colors";
@import "spacing";

// https://stackoverflow.com/questions/18894981/sass-not-scss-syntax-for-css3-keyframe-animation
@keyframes pulseOutIn {
    0% {
        box-shadow: 0 0 0 0px rgba($orange, 1); }
    50% {
        box-shadow: 0 0 0 (2 * $std-space) rgba($orange, .2); }
    100% {
        box-shadow: 0 0 0 0px rgba($orange, 1); } }

@keyframes pulseOut {
    0% {
        box-shadow: 0 0 0 0px rgba($orange, 1); }
    70% {
        box-shadow: 0 0 0 (2 * $std-space) rgba($orange, 0); } }

// http://www.tipue.com/blog/css-pulse-buttons/
.navItem-appear {
    animation: pulseOutIn 1s 1; }

.pulse {
    animation: pulseOut 1s infinite; }
